import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Box, ResponsiveContext } from 'grommet';

const WhatIsForklore = () => {
  let appDirection = 'column';
  let backImageHeight = '40vw';
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        if (size !== 'xsmall' && size !== 'small') {
          appDirection = 'row';
          backImageHeight = '40vw';
        } else {
          appDirection = 'column';
          backImageHeight = '500px';
        }
        return (
          <>
            <Box>
              <Box
                style={{
                  marginBottom: '1.5rem',
                  background: 'linear-gradient(180deg, #f4f5f7, #b2b2b2)',
                  height: backImageHeight,
                  position: 'relative',
                }}
              >
                <Box
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                    width: '100%',
                  }}
                >
                  <h2>
                    <Box align="center" justify="center">
                      <Box
                        direction={appDirection}
                        align="center"
                        justify="center"
                        style={{
                          display: 'inline-flex',
                          borderBottom: 'solid 2px',
                        }}
                      >
                        <StaticImage
                          objectFit="contain"
                          src="../../images/FORKLORE.png"
                          height={150}
                          formats={['auto', 'webp', 'avif']}
                          alt={'FORKLORE'}
                          imgClassName="ofi"
                        />
                        <span>とは</span>
                      </Box>
                    </Box>
                  </h2>
                  <Box
                    align="center"
                    justify="center"
                    style={{
                      marginBottom: '30px',
                      marginTop: '20px',
                      fontSize: 'min(2.5rem, 2.5vw)',
                      lineHeight: '3rem',
                      fontWeight: 'bold',
                      textShadow: `5px 5px 8px #ffffff,
                  -5px  5px 8px #ffffff,
                   5px -5px 8px #ffffff,
                  -5px -5px 8px #ffffff,
                   5px  0px 8px #ffffff,
                   0px  5px 8px #ffffff,
                  -5px  0px 8px #ffffff,
                   0px -5px 8px #ffffff`,
                    }}
                  >
                    <span>情報通信テクノロジーによりデータを収集、蓄積、分析し、</span>
                    <span>お客様の「より良い現場づくり」を</span>
                    <span>サポートするサービス群です</span>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};

export default WhatIsForklore;
