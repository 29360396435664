import * as React from 'react';
import Layout from '../components/layout/layout';
import Seo from '../components/seo';
import Top from '../components/home/top';
import WhatIsForklore from '../components/home/whatIsForklore';
import ApplicationAvailable from '../components/home/applicationAvailable';
import ApplicationNotAvailable from '../components/home/applicationNotAvailable';
import News from '../components/home/news';

const IndexPage = () => (
  <Layout>
    <Seo title="FORKLORE" />
    <Top />
    <WhatIsForklore />
    <ApplicationAvailable />
    <News />
  </Layout>
);

export default IndexPage;
