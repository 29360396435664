import React, { useState, useEffect } from 'react';
import { Box, ResponsiveContext, Button } from 'grommet';
import { callGetApi } from '../../components/internal/callApi';
const functionURL = process.env.FUNCTION_URI
  ? process.env.FUNCTION_URI + `/api/ReadFile?type=news`
  : `/api/ReadFile?type=news`;

const News = () => {
  const DEFAULT_ROW_COUNT = 5;
  let appDirection = 'column';
  let yearFontSize = '0.7rem';
  let messageMarginLeft = '0.5rem';
  let messageLineHeight = '1.2';

  const [isOpened, setIsOpened] = useState(false);
  const [news, setNews] = useState([]);

  let buttonText = isOpened ? 'show less' : 'show more';

  // const newsDummy = [
  //   { date: '2022.1.1', message: 'test1' },
  //   { date: '2022.1.2', message: 'test2' },
  //   { date: '2022.1.3', message: 'test3' },
  //   { date: '2022.1.4', message: 'test4' },
  //   { date: '2022.1.5', message: 'test5' },
  //   { date: '2022.1.6', message: 'test6' },
  // ];
  useEffect(() => {
    async function fetchData() {
      setNews((state) => state.filter((item) => item.date === ''));
      // let list = newsDummy;
      const response = await callGetApi(functionURL);
      if (response.status === 200) {
        let list = await response.json();
        list.map((rec) => {
          setNews((news) => [
            ...news,
            {
              date: rec.date,
              message: rec.message,
            },
          ]);
          return '';
        });
      } else {
        response.json().then((data) => {});
      }
    }
    fetchData();
  }, []);

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        if (size !== 'xsmall' && size !== 'small') {
          appDirection = 'row';
          yearFontSize = '0.9rem';
          messageMarginLeft = 'inherit';
          messageLineHeight = '1.8';
        } else {
          appDirection = 'column';
          yearFontSize = '0.7rem';
          messageMarginLeft = '0.5rem';
          messageLineHeight = '1.2';
        }
        return (
          <>
            <Box
              style={{
                marginBottom: '1.5rem',
              }}
            >
              <Box border="all" pad="medium">
                <h2 style={{ fontSize: '1.2rem', marginBottom: '0.8rem' }}>News</h2>
                {news?.map((rec, index) => {
                  const list = rec.message?.split('\r\n');
                  if (!isOpened && index >= DEFAULT_ROW_COUNT) {
                    return '';
                  }
                  return (
                    <Box
                      direction={appDirection}
                      style={{
                        marginLeft: '0.5rem',
                        marginBottom: '0.5rem',
                        lineHeight: messageLineHeight,
                      }}
                      key={index}
                    >
                      <Box
                        style={{
                          flexBasis: '10%',
                        }}
                      >
                        <span style={{ fontSize: yearFontSize }}>{rec.date}</span>
                      </Box>
                      <Box
                        style={{
                          flexBasis: '90%',
                          marginLeft: messageMarginLeft,
                        }}
                      >
                        {list.map((x, textIndex) => {
                          return (
                            <Box key={'text_' + textIndex}>
                              <span>{x}</span>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  );
                })}
                {news.length > DEFAULT_ROW_COUNT ? (
                  <Box id="newsMore" style={{ width: '100%', textAlign: 'right' }}>
                    <a
                      label={buttonText}
                      href="javascript:void(0)"
                      onClick={() => {
                        setIsOpened(!isOpened);
                      }}
                    >
                      {buttonText}
                    </a>
                  </Box>
                ) : (
                  ''
                )}
              </Box>
            </Box>
          </>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};

export default News;
