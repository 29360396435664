import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Box, ResponsiveContext, Text } from 'grommet';

const Top = () => {
  let text1 = '豊田自動織機 がご提供する';
  let text2 = 'サブスクサービス「FORKLORE」';
  let text3 = 'サービス開始から次々に新しいコンテンツを';
  let text4 = 'リリースしています';

  let topBackImageHeight = '80vh';
  return (
    <ResponsiveContext.Consumer align="center" justify="around">
      {(size) => {
        if (size !== 'xsmall' && size !== 'small') {
          if (size !== 'large' && size !== 'xlarge') {
            topBackImageHeight = '600px';
          } else {
            topBackImageHeight = '600px';
          }
        } else {
          topBackImageHeight = '60vw';
        }
        if (size !== 'xsmall' && size !== 'small') {
          //PCレイアウト
          return (
            <Box align="center" style={{ marginBottom: '1.5rem' }}>
              <Box
                width={'90%'}
                height={topBackImageHeight}
                margin="0 auto"
                style={{ position: 'relative' }}
              >
                <StaticImage
                  objectFit="cover"
                  src="../../images/home/home.jpeg"
                  formats={['auto', 'webp', 'avif']}
                  alt={'FORKLORE'}
                  imgClassName="ofi"
                  imgStyle={{ width: '100%' }}
                />
                <Box
                  style={{
                    position: 'absolute',
                    top: '70%',
                    left: '20%',
                    transform: 'translate(-30%,-50%)',
                    zIndex: 10,
                  }}
                >
                  <Text
                    color="#404040"
                    style={{
                      whiteSpace: 'nowrap',
                      textShadow: `5px 5px 8px #ffffff,
                      -5px  5px 8px #ffffff,
                      5px -5px 8px #ffffff,
                      -5px -5px 8px #ffffff,
                      5px  0px 8px #ffffff,
                      0px  5px 8px #ffffff,
                      -5px  0px 8px #ffffff,
                      0px -5px 8px #ffffff`,
                    }}
                  >
                    <p className="fontOnTopSub">
                      <strong>{text1}</strong>
                      <strong>{text2}</strong>
                      <strong>{text3}</strong>
                      <strong>{text4}</strong>
                    </p>
                  </Text>
                </Box>
              </Box>
            </Box>
          );
        } else {
          //タブレット・スマホレイアウト
          return (
            <Box
              style={{
                position: 'relative',
                marginBottom: '1.5rem',
              }}
            >
              <Text
                color="#404040"
                style={{
                  marginLeft: '3rem',
                  position: 'absolute',
                  top: '60%',
                  zIndex: 10,
                  textShadow: `5px 5px 8px #ffffff,
                    -5px  5px 8px #ffffff,
                     5px -5px 8px #ffffff,
                    -5px -5px 8px #ffffff,
                     5px  0px 8px #ffffff,
                     0px  5px 8px #ffffff,
                    -5px  0px 8px #ffffff,
                     0px -5px 8px #ffffff`,
                }}
              >
                <p className="paraShortMb" style={{ fontSize: '2.3vw' }}>
                  <strong>{text1}</strong>
                  <strong>{text2}</strong>
                  <strong>{text3}</strong>
                  <strong>{text4}</strong>
                </p>
              </Text>
              <Box align="center">
                <StaticImage
                  objectFit="contain"
                  src="../../images/home/home.jpeg"
                  formats={['auto', 'webp', 'avif']}
                  alt={'FORKLORE'}
                  imgClassName="ofi"
                  imgStyle={{ width: '100%' }}
                />
              </Box>
            </Box>
          );
        }
      }}
    </ResponsiveContext.Consumer>
  );
};
export default Top;
