import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Box, ResponsiveContext } from 'grommet';

const ApplicationAvailable = () => {
  let libTop = '50%';
  let backImageHeight = '40vw';
  let imageBasis = '53%';
  let textBasis = '47%';
  const imageWidth = 1500;
  const titleDriveRecorderConnect = 'ドラレコConnect';
  const discriptionDriveRecorderConnect = [
    'ドライブレコーダーの',
    '情報を',
    'クラウドに',
    '蓄積し、',
    '可視化します。',
  ];
  const titleBatteryConnect = 'バッテリーConnect';
  const discriptionBatteryConnect1 = [
    'フォークリフト用',
    'リチウムイオン電池の',
    'エネルギー',
    '収支を',
    '可視化します。',
  ];
  const discriptionBatteryConnect2 = ['トヨタL&F販売店取扱いの', 'リチウムイオン電池'];
  const discriptionBatteryConnect3 = ['（車両連動タイプまたはENELORE）に', '対応しています。'];
  const titleSafeDriving = '運転動画AI解析';
  const discriptionSafeDriving = [
    'ドラレコの',
    '画像データを',
    '解析し、',
    'フォークリフトの',
    '安全運転・オペレーターの安全行動に',
    'つながる',
    '運転評価を',
    '行います。',
  ];

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        if (size !== 'xsmall' && size !== 'small') {
          libTop = '40%';
          backImageHeight = '500px';
          // if (size !== 'large' && size !== 'xlarge') {
          //   backImageHeight = '500px';
          // } else {
          //   backImageHeight = '500px';
          // }
        } else {
          libTop = '50%';
          backImageHeight = '40vw';
        }
        if (size !== 'xsmall' && size !== 'small') {
          //PCレイアウト
          return (
            <>
              <Box height={backImageHeight} style={{ marginBottom: '2rem' }}>
                <a className="notStyledLink" href="/driverecorderSolution#driverecorderConnect">
                  <Box direction="row">
                    <Box basis={imageBasis} height={backImageHeight}>
                      <StaticImage
                        objectFit="cover"
                        objectPosition="center center"
                        src="../../images/home/iStock-147277747.jpg"
                        formats={['auto', 'webp', 'avif']}
                        alt={titleDriveRecorderConnect}
                        imgClassName="ofi"
                        width={imageWidth}
                        imgStyle={{ width: '100%' }}
                      />
                    </Box>
                    <Box basis={textBasis} height={backImageHeight}>
                      <Box
                        style={{
                          position: 'relative',
                          margin: 'auto 0',
                        }}
                      >
                        <Box style={{ margin: '0 auto 1rem' }}>
                          <Box direction="row">
                            <Box width="5vw">
                              <StaticImage
                                width={50}
                                height={50}
                                objectFit="contain"
                                src="../../icons/baseline_linked_camera_black_48dp.png"
                                formats={['auto', 'webp', 'avif']}
                                alt={titleDriveRecorderConnect}
                                imgClassName="ofi"
                              />
                            </Box>
                            <h3
                              style={{
                                fontSize: '3.2vw',
                                marginLeft: '0.2rem',
                                marginBottom: '0rem',
                              }}
                            >
                              {titleDriveRecorderConnect}
                            </h3>
                          </Box>
                        </Box>
                        <p style={{ marginLeft: '50px' }}>
                          <strong className="fontOnApplicationNotAvailable">
                            {discriptionDriveRecorderConnect.map((text) => {
                              return <span className="nowrap">{text}</span>;
                            })}
                          </strong>
                        </p>
                      </Box>
                    </Box>
                  </Box>
                </a>
              </Box>
              <Box style={{ marginBottom: '2rem' }}>
                <a className="notStyledLink" href="/batterySolution">
                  <Box direction="row">
                    <Box basis={textBasis} height={backImageHeight}>
                      <Box
                        style={{
                          position: 'relative',
                          margin: 'auto 0',
                        }}
                      >
                        <Box style={{ margin: '0 auto 1rem' }}>
                          <Box direction="row">
                            <Box width="5vw">
                              <StaticImage
                                width={40}
                                height={60}
                                objectFit="contain"
                                src="../../icons/linked_battery_charging_full_black.png"
                                formats={['auto', 'webp', 'avif']}
                                alt={titleBatteryConnect}
                                imgClassName="ofi"
                              />
                            </Box>
                            <h3
                              style={{
                                fontSize: '3.2vw',
                                marginLeft: '0.2rem',
                                marginBottom: '0rem',
                              }}
                            >
                              {titleBatteryConnect}
                            </h3>
                          </Box>
                        </Box>
                        <p style={{ marginLeft: '50px', marginBottom: '5px' }}>
                          <strong className="fontOnApplicationNotAvailable">
                            {discriptionBatteryConnect1.map((text) => {
                              return <span className="nowrap">{text}</span>;
                            })}
                          </strong>
                        </p>
                        <Box direction="row">
                          <span
                            className="fontOnApplicationAvailableLibSub"
                            style={{ margin: '0 8px 0 60px' }}
                          >
                            {discriptionBatteryConnect2.map((text) => {
                              return <span className="nowrap">{text}</span>;
                            })}
                            {/* <StaticImage
                              width={90}
                              objectFit="contain"
                              src="../../images/ENELORE.png"
                              formats={['auto', 'webp', 'avif']}
                              alt={'ENELORE'}
                              style={{
                                margin: '0 2px 0 2px',
                              }}
                              imgClassName="ofi"
                            /> */}
                            {discriptionBatteryConnect3.map((text) => {
                              return <span className="nowrap">{text}</span>;
                            })}
                          </span>
                        </Box>
                      </Box>
                    </Box>
                    <Box basis={imageBasis} height={backImageHeight}>
                      <StaticImage
                        objectFit="cover"
                        objectPosition="center center"
                        src="../../images/home/iStock-1158185141.jpg"
                        formats={['auto', 'webp', 'avif']}
                        alt={titleBatteryConnect}
                        imgClassName="ofi"
                        width={imageWidth}
                        imgStyle={{ width: '100%' }}
                      />
                    </Box>
                  </Box>
                </a>
              </Box>
              <Box style={{ marginBottom: '2rem' }}>
                <Box direction="row">
                  <Box basis={imageBasis} height={backImageHeight}>
                    <StaticImage
                      objectFit="cover"
                      objectPosition="center center"
                      src="../../images/home/iStock-1263390703.jpg"
                      formats={['auto', 'webp', 'avif']}
                      alt={titleSafeDriving}
                      imgClassName="ofi"
                      width={imageWidth}
                      imgStyle={{ width: '100%' }}
                    />
                  </Box>
                  <Box basis={textBasis} height={backImageHeight}>
                    <Box
                      style={{
                        position: 'relative',
                        margin: 'auto 0',
                      }}
                    >
                      <Box style={{ margin: '0 50px 0 0' }}>
                        <Box style={{ margin: '0 auto 1rem' }}>
                          <h3
                            style={{
                              fontSize: '3.2vw',
                              marginLeft: '0.2rem',
                              marginBottom: '0rem',
                            }}
                          >
                            {titleSafeDriving}
                          </h3>
                        </Box>
                      </Box>
                      <p style={{ marginLeft: '20px' }}>
                        <strong className="fontOnApplicationNotAvailable">
                          {discriptionSafeDriving.map((text) => {
                            return <span className="nowrap">{text}</span>;
                          })}
                        </strong>
                      </p>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          );
        } else {
          //タブレット・スマホレイアウト
          return (
            <>
              <Box style={{ marginBottom: '2rem' }}>
                <a href="/driverecorderSolution#driverecorderConnect" style={{ color: '#444' }}>
                  <Box
                    style={{
                      backgroundColor: 'transparent',
                      height: backImageHeight,
                      width: '100%',
                      position: 'relative',
                    }}
                  >
                    <Box
                      style={{
                        position: 'absolute',
                        top: '40%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        width: '100%',
                        zIndex: 10,
                        textShadow: `2px 2px 3px #000000,
                    -2px  2px 3px #000000,
                    2px -2px 3px #000000,
                    -2px -2px 3px #000000,
                    2px  0px 3px #000000,
                    0px  2px 3px #000000,
                    -2px  0px 3px #000000,
                    0px -2px 3px #000000`,
                      }}
                    >
                      <Box>
                        <Box justify="center" align="center">
                          <Box direction="row" justify="center" align="center">
                            <Box width="5vw">
                              <StaticImage
                                width={50}
                                height={50}
                                objectFit="contain"
                                src="../../icons/baseline_linked_camera_white_48dp.png"
                                formats={['auto', 'webp', 'avif']}
                                alt={titleDriveRecorderConnect}
                                imgClassName="ofi"
                              />
                            </Box>
                            <h3
                              style={{
                                fontSize: '3.2vw',
                                marginLeft: '0.5rem',
                                marginBottom: '0rem',
                                color: '#ffffff',
                              }}
                            >
                              {titleDriveRecorderConnect}
                            </h3>
                          </Box>
                          <p style={{ textAlign: 'center', margin: '0 20px 0.5rem 20px' }}>
                            <strong
                              className="fontOnApplicationNotAvailable"
                              style={{ color: '#ffffff' }}
                            >
                              {discriptionDriveRecorderConnect.map((text) => {
                                return <span className="nowrap">{text}</span>;
                              })}
                            </strong>
                          </p>
                        </Box>
                      </Box>
                    </Box>
                    <Box direction="row" height={backImageHeight}>
                      <Box align="center" justify="center">
                        <StaticImage
                          objectFit="cover"
                          objectPosition="center center"
                          src="../../images/home/iStock-147277747.jpg"
                          formats={['auto', 'webp', 'avif']}
                          alt={titleDriveRecorderConnect}
                          imgClassName="ofi"
                          width={imageWidth}
                          imgStyle={{ width: '100%' }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </a>
              </Box>
              <Box style={{ marginBottom: '2rem' }}>
                <a href="/batterySolution">
                  <Box
                    style={{
                      backgroundColor: 'transparent',
                      height: backImageHeight,
                      width: '100%',
                      position: 'relative',
                    }}
                  >
                    <Box
                      style={{
                        position: 'absolute',
                        top: libTop,
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        width: '100%',
                        zIndex: 10,
                        color: '#ffffff',
                        textShadow: `2px 2px 3px #000000,
                    -2px  2px 3px #000000,
                    2px -2px 3px #000000,
                    -2px -2px 3px #000000,
                    2px  0px 3px #000000,
                    0px  2px 3px #000000,
                    -2px  0px 3px #000000,
                    0px -2px 3px #000000`,
                      }}
                    >
                      <Box>
                        <Box justify="center" align="center">
                          <Box direction="row" justify="center" align="center">
                            <Box width="5vw">
                              <StaticImage
                                className="imageBatteryWithBlurBlack"
                                width={40}
                                height={50}
                                objectFit="contain"
                                src="../../icons/linked_battery_charging_full_white.png"
                                formats={['auto', 'webp', 'avif']}
                                alt={titleBatteryConnect}
                                imgClassName="ofi"
                              />
                            </Box>
                            <h3
                              style={{
                                color: '#ffffff',
                                fontSize: '3.2vw',
                                marginLeft: '0.5rem',
                                marginBottom: '0rem',
                              }}
                            >
                              {titleBatteryConnect}
                            </h3>
                          </Box>
                          <p
                            className="fontOnApplicationAvailableLib"
                            style={{
                              color: '#ffffff',
                              textAlign: 'center',
                              margin: '0 8px 0.5rem 8px',
                            }}
                          >
                            <strong align="center" style={{ color: '#ffffff' }}>
                              {discriptionBatteryConnect1.map((text) => {
                                return <span className="nowrap">{text}</span>;
                              })}
                            </strong>
                          </p>
                          <Box direction="row" justify="center" align="center">
                            <span
                              align="center"
                              className="fontOnApplicationAvailableLibSub"
                              style={{ color: '#ffffff', margin: '0 8px 0 8px' }}
                            >
                              {discriptionBatteryConnect2.map((text) => {
                                return <span className="nowrap">{text}</span>;
                              })}
                              {/* <StaticImage
                                className="imageEneloreWithBlurBlack"
                                width={70}
                                objectFit="contain"
                                src="../../images/ENELORE.png"
                                formats={['auto', 'webp', 'avif']}
                                alt={'ENELORE'}
                                style={{
                                  margin: '0 2px 0 2px',
                                }}
                                imgClassName="ofi"
                              /> */}
                              {discriptionBatteryConnect3.map((text) => {
                                return <span className="nowrap">{text}</span>;
                              })}
                            </span>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box direction="row" height={backImageHeight}>
                      <Box align="center" justify="center">
                        <StaticImage
                          objectFit="cover"
                          objectPosition="center center"
                          src="../../images/home/iStock-1158185141.jpg"
                          formats={['auto', 'webp', 'avif']}
                          alt={'リチウム電池Connect'}
                          width={imageWidth}
                          imgClassName="ofi"
                          imgStyle={{ width: '100%' }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </a>
              </Box>
              <Box style={{ marginBottom: '2rem' }}>
                <Box
                  style={{
                    backgroundColor: 'transparent',
                    height: backImageHeight,
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  <Box
                    style={{
                      position: 'absolute',
                      top: '45%',
                      left: '50%',
                      transform: 'translate(-50%,-50%)',
                      width: '100%',
                      zIndex: 10,
                      textShadow: `2px 2px 3px #ffffff,
                                  -2px  2px 3px #ffffff,
                                  2px -2px 3px #ffffff,
                                  -2px -2px 3px #ffffff,
                                  2px  0px 3px #ffffff,
                                  0px  2px 3px #ffffff,
                                  -2px  0px 3px #ffffff,
                                  0px -2px 3px #ffffff`,
                    }}
                  >
                    <Box>
                      <Box justify="center" align="center">
                        <h3
                          style={{
                            fontSize: '3.2vw',
                            marginLeft: '0.5rem',
                            marginBottom: '0rem',
                          }}
                        >
                          {titleSafeDriving}
                        </h3>
                        <p style={{ textAlign: 'center', margin: '0 20px 0.5rem 20px' }}>
                          <strong className="fontOnApplicationNotAvailable">
                            {discriptionSafeDriving.map((text) => {
                              return <span className="nowrap">{text}</span>;
                            })}
                          </strong>
                        </p>
                      </Box>
                    </Box>
                  </Box>
                  <Box direction="row" height={backImageHeight}>
                    <Box align="center" justify="center">
                      <StaticImage
                        objectFit="cover"
                        objectPosition="center center"
                        src="../../images/home/iStock-1263390703.jpg"
                        formats={['auto', 'webp', 'avif']}
                        alt={titleSafeDriving}
                        width={imageWidth}
                        imgClassName="ofi"
                        imgStyle={{ width: '100%' }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          );
        }
      }}
    </ResponsiveContext.Consumer>
  );
};

export default ApplicationAvailable;
